::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    background-clip: padding-box;
    min-height: 28px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}

.layout-header-box{
    display: flex;
    justify-content:space-between;
    .user-info-box{
        padding-right: 10px;
    }
}
.logo{
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    margin: 16px;
    color: #fff;
}
.site-layout{
    height: calc(100vh - 60px);
    overflow:hidden;
    .ant-menu{
        height: 100%;
        overflow: hidden;
    }
}
.layout-box{
    .ant-layout-header{
        background-color:#000000bf;//#25303d;//#2B383D;//#0972d3;// #232F3C;//#1890ff;
    }
}

.main-box{
    padding: 8px;
    margin:12px 0;
    height: calc(100% - 24px);
    overflow-y: scroll;
}

.breaccrumb-box{
    border: 1px solid #91d5ff;
    box-shadow: 0 1px 2px 0 rgb(101 129 156 / 8%); 
    height: 40px;
    line-height: 30px;
    padding: 5px;
    margin-bottom: 12px;
    background-color: #e6f7ff;
    display: flex;
    border-radius: 4px;

    .breaccrumb-left{
        span{
            color: #666;
        }
        color: #1890ff;
        width: calc(100% - 300px);
    }
    .breaccrumb-right{
        width: 300px;
        text-align: right;
    }
}

.query-form-box{
    margin-bottom: 12px;
    .ant-form-inline .ant-form-item{
        margin-bottom: 10px;
    }
}
.page-box{
    text-align: right;
    padding: 12px 0;
}
.user-info-box{
    color: #fff;
}

.my-inline-form{
    .ant-form-item{
        width: 380px !important;
        height: 50px;
    }
    .ant-form-item > .ant-form-item-label{
        width: 190px;
    }
    .ant-form-item-with-help{
        margin-bottom: 22px;
        height: 50px;
    }
}
.ant-drawer-body{
    padding: 12px;
}

/**文件管理**/
.tab-box-content {
    height: calc(100% - 65px);
}

.tab-box-img {

    // background-color: rgb(250, 90, 165);
    .tab-box-img-body {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;

        li {
            width: calc((100% - 12px - 12px - 12px - 12px) / 5);
            height: 210px;
            margin: 4px 12px 12px 0;
            // border: rgb(235, 234, 234) solid 2px;
            // background-color: #c9fafc;
        }

        li:nth-child(5n) {
            margin-right: 0px !important;
            // background: red;
        }

        .tab-item-img-box {
            padding: 3px;
            width: 100%;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: #eaebec solid 2px;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
        .tab-item-img-box:hover{
            border: #1890ff solid 2px;
        }

        .tab-item-active {
            border: #ff8018 solid 2px;
        }

        .tab-item-filename {
            height: 40px;
            line-height: 34px;
            padding: 3px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            background-color: #fff;
        }
    }
}

.tab-title {
    display: flex;
    // justify-content: space-between;
}
.tab-current-btn{
    margin-right: 10px;
}
.file-box-page{
    height: 40px;
}
.tab-box-pannel{
    height: calc(100% - 80px);
    overflow-y: scroll;
    margin-bottom: 10px;
    // background-color: #08979c;
}
.tab-box-file-body{
    padding: 10px 0;
    li{
        display: flex;
        height: 40px;
        line-height: 40px;
        border-bottom: #dcdcdc solid 1px;
        div:nth-child(1){
            width: calc(100% - 160px - 150px);
            padding-left: 8px;
        }
        div:nth-child(2){
            width: 160px
        }
        div:nth-child(3){
            width: 150px
        }
    }
    li:hover{
        background-color:#e6f7ff;
        color:  #1890ff;
    }
    .tab-item-file-active{
        background-color:#e6f7ff;
        color:  #1890ff;
    }

}

.myupload-box-list {
    display: flex;
    // overflow-x: scroll;

    li {
        margin-right: 6px;
        width: 75px;
        height: 97px;
        margin-bottom: 3px;
        text-align: center;

        a {
            padding: 2px;
            display: block;
            border: #f0f0f0 solid 1px;
            height: calc(100% - 24px);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a:hover {
            border: #1890ff solid 1px;
        }

        .action-box {
            text-align: center;
            cursor: pointer;
            font-size: 12px
        }

        img {
            width: auto;
            height: auto;
            max-width: 71px;
            max-height: 71px;
            vertical-align:middle;
        }
    }

    .my-upload-box {
        width: 77px;
        height: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        border: 1px solid #f0f0f0;
        cursor: pointer;
        color: #8c8e91;
        margin-bottom: 23px;
    }

}

.myupload-attach-list {
    display: flex;
    // overflow-x: scroll;

    li {
        margin-right: 6px;
        width: 100%;
        margin-bottom: 3px;

        a {
            margin-right: 6px;
        }

        // a:hover {
        //     border: #1890ff solid 1px;
        // }

        .action-box {
            text-align: center;
            cursor: pointer;
            font-size: 12px
        }
    }

}

.my-inline-form{
    .ant-form-item{
        width: 415px !important;
        height: 55px;
    }
    .ant-form-item > .ant-form-item-label{
        width: 190px;
    }
    .ant-form-item-with-help{
        margin-bottom: 22px;
        height: 55px;
    }
}

.auth-one{
    margin-top: 6px;
}
.auth-two{
    padding:5px 0 5px 30px;
    .auth-three{
        padding:5px 0 5px 30px;
        display: flex;
        div{
            
        }
    }
}


.top-pannel {
    display: flex;
    justify-content: space-between;
    // margin: 0 0 25px 0;
    // background-color: #ececec;
    height: 122px;
    border-radius: 6px;
    // padding: 10px;

    li {
        transition: all ease 0.5s;
        margin-right: 12px;
        width: calc(25% - 6px);
        height: 110px;
        border-radius: 6px;
        background-color: #ffffff;
        display: flex;

        .calc-box-item-left {
            width: 30%;
            font-size: 36px;
            text-align: center;
            line-height: 110px;
        }

        .calc-box-item-right {
            width: 70%;

            div:nth-child(1) {
                padding-top: 20px;
                font-size: 26px;
                color: #000;
            }

            div:nth-child(2) {
                color: #697b8c;
            }
        }
    }

    li:last-child {
        margin-right: 0px;
    }
    li:hover {
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1), 0 4px 15px 0 rgba(0, 0, 0, .2);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1), 0 4px 15px 0 rgba(0, 0, 0, .2);
        margin-top: -3px;
    }
}

.pannel-box{
    display: flex;
    align-items: flex-start;
    // background-color: #ececec;
    // padding: 12px;
    // width: calc(100% - 12px - 12px);
    .pannel-left{
        width: 50%;
        // width: calc(60% - 12px);
        margin-right: 12px;
        flex: 1;
    }
    .pannel-right{

        width: calc(50% - 15px);
        .hot-goods{
            padding: 12px;
            background: #ffffff;
        }
        .maps{
            margin-top: 12px;
            padding: 12px;
            background: #ffffff;
            min-height: 305px;
        }
    }
}

.pannel-title{
    height: 40px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    border-bottom: #ececec solid 1px;
    color: #697b8c;
    padding-bottom: 6px;
    .pannel-title-icon{
        font-size: 20px;
    }
}


// 规格
.spec-item{
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    .spec-title{
        width: 100px;
        font-weight: bold;
        text-align: right;
        padding-right: 12px;
    }
    .spec-item-li{
        width: 100px;
    }
}
.spec-table{
    width: 100%;
    margin-bottom: 20px;
    tr{
        background-color:#e6e6e6;
        td{
            background-color: #fff;
            border: #e6e6e6 solid 1px;
            padding: 4px;
            // .spec-img{ width: 60px; height: 60px; margin-right: 5px;}
            .spec-input{ width: 100%;}
            text-align: center;
        }
    }

    thead.spec-table-title{
        th{
            border: #e6e6e6 solid 1px;
            padding: 5px;
            background-color:#d1d0d0;
            font-weight: normal;
            // width: calc(100% / 7 );
            .ant-input{ width: 100%;}
        }
        th:nth-child(1){
            width: 70px;
            text-align: left;
        }
        th:nth-child(2){
            width:  120px;
        }
        th:nth-child(3){
            width: 100px;
        }
        th:nth-child(4){
            width:  80px;
        }
        th:last-child{
            width:  55px;
        }
    }
}

.goods-spec-box{
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 15px 0;

    .goods-spec-item{
        display: flex;
        margin:0 15px 5px 0;
        .goods-spec-item-input{ width: 140px;}
        .goods-spec-item-btn{ margin: 0px 0 0 4px; height: 31px;}
    }
}

.atrr-box{
    margin-left: 0px;
    width: 850px;
}

.spec-thumb-box{
    width: 50px;
    height: 50px;
    // background-color: red;
    position: relative;
    margin: 0 auto;
    a{
        display: block;
        margin-top: 6px;
    }
    .spec-thumb-del{
        position: absolute;
        top: 0px;
        right: 0px;
        width: 15px;
        height: 15px;
        line-height: 15px;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        font-size: 19px;
    }
    .spec-thumb-img{
        padding-top: 1px;
    }
}

.relate_title{ width: 70px;}
.relate_list{ width: calc(100% - 70px - 43px);}
.relate_li_box{ padding: 10px 0; }
.relate_li_box{
    li{ height: 30px; line-height: 30px; display: flex;
        p{ margin-left: 20px; color: red; cursor: pointer;}
    }
} 



