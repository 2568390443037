@import '~antd/dist/antd.css';

* {
    margin: 0;
    padding: 0;
}

html,
body,
#root {
    height: 100%;
}

// ul,
// li {
//     list-style: none;
// }

::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    background-clip: padding-box;
    min-height: 28px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}

// 用户登录
.login-container {
    height: 100%;
    width: 100%;
    background: #bbd7fb;
    overflow: hidden;

    video {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        /*保证视频内容始终居中*/
        transform: translate(-50%, -50%);
    }

    .login-box {
        width: 1000px;
        margin: 120px auto 0 auto;
        height: 470px;
        display: flex;
        position: relative;

        .login-title {
            font-size: 26px;
            padding: 5px 0 25px 0;
            text-align: center;
        }

        .login-img {
            background: url("../images/login-bg.png") center center no-repeat;
            background-size: 100%;
            width: 550px;
            height: 330px;
            margin:70px 10px 0 0;
        }

        .login-form {
            width: calc(100% - 550px - 100px);
            height: 285px;
            position: absolute;
            top: 95px;
            right: 10px;
            padding: 30px;
            background-color: #fff;
            border-radius: 10px;
            text-align: left;
            z-index: 1000;
        }
    }
}