.member-header{
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 12px;
    .member-logo{
        width: 75px;
        height: 75px;
        img{
            width: 90%;
            height: 90%;
            border-radius: 50%;
            border:#999 solid 1px;
        }
    }
    .member-info{
        width: calc(100% - 120px);
        padding-left: 20px;
        .member-info-top{
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;
            li{
                width: 50%;
                height: 27px;
                margin-bottom: 0;
                span{
                    color: #787373;
                }
            }
        }
    }
}
.member-body{
    display: flex;
    flex-wrap: wrap;
    li{
        width: 50%;
        height: 27px;
        margin-bottom: 0;
        span{
            color: #787373;
        }
    }
}
.member-addr{
    border-top: 1px solid #f0f0f0;
    margin-top: 12px;
    h3{ padding-top: 7px;}
    .member-addr-item{
        border-bottom: #cbc5c5 dashed 1px;
        padding-top: 5px;
        li{
            height: 27px;
            span{
                color: #787373;
            }
        }
    }
    .member-addr-item:last-child{
        border-bottom: none;
    }
}